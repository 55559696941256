import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services";
import { useTranslation } from "react-i18next";
import jwt from "jwt-decode";
import { IProviderProps } from "../tenant/tenant.interfaces";
import {
  ICreateAddress,
  ICreateUser,
  ILegalTermsRequest,
  ILogin,
  IResetPassword,
  ISendPasswordRecoveryCode,
  IUpdatePassword,
  IUpdateUser,
  IUser,
  IUserFromTenant,
} from "./user.interface";
import { ISale } from "../sales/sales.interface";

export const SellerContext = createContext<any | null>(null);
export const SellerProvider = ({ children }: IProviderProps) => {
  const [seller, setSeller] = useState<IUser | null>(null);
  const [allSellers, setAllSellers] = useState<IUserFromTenant[] | null>(null);
  const [isLogged, setIsLogged] = useState(false);
  const [sales, setSales] = useState<ISale[]>([]);
  const [token, setToken] = useState(
    window.localStorage.getItem("SPP-Session-Token") || ""
  );
  const [accessToken, setAccessToken] = useState(
    window.localStorage.getItem("SPP-Access-Token") || ""
  );
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(true);
  const roleAdmin = ["SystemAdmin"];
  const roleFinance = ["SystemAdmin", "Finance"];
  const roleRecruit = ["Recruit"];
  const [apiLoading, setApiLoading] = useState(false);
  const [pageMounting, setPageMounting] = useState(false);
  const verifyUserLoggedIn = async () => {
    setApiLoading(true);
    if (token) {
      const validToken = await verifyToken();
      if (!validToken) {
        const customId = "custom-id-toast";
        toast.error(t("session_expired"), {
          toastId: customId,
        });

        setApiLoading(false);
        return Logout();
      }

      setIsLogged(true);
      setApiLoading(false);
      return sales;
    } else {
      setApiLoading(false);
    }
  };

  const Login = async (data: ILogin) => {
    data.path = path;
    setApiLoading(true);
    await api
      .post("/login", data)
      .then(async (res) => {
        window.localStorage.setItem("SPP-Session-Token", res.data.IdToken);
        window.localStorage.setItem("SPP-Access-Token", res.data.AccessToken);
        setToken(res.data.IdToken);
        setAccessToken(res.data.AccessToken);
        setApiLoading(false);
        const tokenDecoded = jwt<any>(res.data.IdToken);
        setIsActive(true);
        setIsLogged(true);
        await verifyToken();
      })
      .catch((err) => {
        setApiLoading(false);

        if (err.response.data.message === "User is not confirmed.") {
          toast.error(t("user_unconfirmed"));
        } else {
          toast.error(t("invalid_login"));
        }
      });
  };

  const registerUser = async (data: ICreateUser) => {
    delete data.confirm;
    setApiLoading(true);
    const register = await api
      .post("/users", data)
      .then((res) => {
        setApiLoading(false);
        toast.success(t("user_created"));
        navigate(`/${path}`);
        return true;
      })
      .catch((err) => {
        setApiLoading(false);
        if (err.response.data.message === "Email already exists") {
          toast.error(t("email_duplicated"));
          return false;
        } else {
          toast.error(err.response.data.message);
          return false;
        }
      });

    if (register) {
      return true;
    }
  };

  const Logout = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  const getSeller = () => {
    setApiLoading(true);

    api
      .get("/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setApiLoading(false);
        res.data.date_birth = res.data.date_birth.split("T")[0];
        setSeller(res.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setApiLoading(false);
      });
  };

  const getAllSellers = () => {
    setPageMounting(true);
    api
      .get("/sellers", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAllSellers(res.data);
        setPageMounting(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setPageMounting(false);
      });
  };
  const updateUser = async (data: IUpdateUser) => {
    setApiLoading(true);
    if (data.confirm) {
      delete data.confirm;
    }
    let dataFile: FormData;
    if (data.fileInput && data.fileInput.length !== 0) {
      dataFile = new FormData();
      dataFile.append("file", data.fileInput[0]);
    }
    delete data.fileInput;
    await api
      .patch(`/users`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (res) => {
        if (dataFile) {
          await api
            .post(`/users/file`, dataFile, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              setApiLoading(false);
              getSeller();
              navigate(`/${path}/profile`);
            })
            .catch((err) => {
              setApiLoading(false);
              toast.error(err.response.data.message);
              console.log(err.response);
            });
        } else {
          setApiLoading(false);
          getSeller();
          navigate(`/${path}/profile`);
        }
      })
      .catch((err) => {
        setApiLoading(false);

        if (err.response.data.message === "Email already exists") {
          toast.error(t("email_duplicated"));
        }
        toast.error(err.response.data.message);
        console.log(err.response);
      });
  };

  const verifyToken = async () => {
    setApiLoading(true);
    const tokenVerify = window.localStorage.getItem("SPP-Session-Token") || "";
    let verify;
    await api
      .get("/user", {
        headers: { Authorization: `Bearer ${tokenVerify}` },
      })
      .then((res) => {
        verify = true;
        setIsActive(true);
        res.data.date_birth = res.data.date_birth.split("T")[0];
        setSeller(res.data);
        setSales(res.data.sales);
      })
      .catch((err) => {
        if (err.response.data.message === "User inactive") {
          verify = [true];
          setIsActive(true);
        } else {
          verify = false;
        }
      });
    if (!verify) {
      setApiLoading(false);

      return false;
    }
    setApiLoading(false);

    return verify;
  };
  const sendActivationToken = async (path: string) => {
    setApiLoading(true);
    api
      .post(
        `/users/activate/email`,
        { path },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setApiLoading(false);
        toast.success(t("sent_code"));
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(t("something_wrong"));
      });
  };
  const activateUser = async (
    code: string,
    email: string,
    tenantId: string
  ) => {
    setApiLoading(true);
    api
      .post(
        `/users/activate`,
        { code, email, tenantId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (res) => {
        toast.success(t("user_confirmed"));
        setApiLoading(false);
        navigate("/" + path);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data);
        toast.error("Link invalido ou expirado.");
        navigate("/" + path);
      });
    setApiLoading(false);
  };
  const updatePassword = async (data: IUpdatePassword) => {
    delete data.confirm;
    setApiLoading(true);
    api
      .post(`/users/password`, data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        setApiLoading(false);
        toast.success(t("changed_password"));
      })
      .catch((err) => {
        setApiLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const resetPassword = async (data: IResetPassword) => {
    delete data.confirm;
    data.path = path;
    setApiLoading(true);
    api
      .post(`/users/password/reset`, data)
      .then((res) => {
        setApiLoading(false);
        toast.success(t("changed_password"));
        navigate("/" + path);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        if (err.response.data.message === "Incorrect username or password.") {
          toast.error(t("wrong_password"));
        } else {
          toast.error(err.response.data.message);
        }
      });
  };
  const sendPasswordToken = async (
    data: ISendPasswordRecoveryCode,
    setPage: React.Dispatch<React.SetStateAction<number>>
  ) => {
    data.path = path;
    setApiLoading(true);
    await api
      .post(`/users/password/recovery`, data)
      .then((res) => {
        setApiLoading(false);
        toast.success(t("recovery_code_sent"));
        if (setPage) setPage(1);
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
  const createAddress = async (data: ICreateAddress) => {
    setApiLoading(true);
    await api
      .post("/address", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
      })
      .catch((err) => {
        setApiLoading(false);

        toast.error(err.response.data.message);
      });
  };
  const createLegalTerms = async (data: ILegalTermsRequest) => {
    setApiLoading(true);
    await api
      .post("/users/terms", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        navigate(`/${path}/candidate`);
      })
      .catch((err) => {
        setApiLoading(false);

        toast.error(err.response.data.message);
      });
  };

  const getRoles = async () => {
    setApiLoading(true);
    const data = await api
      .get(`/roles/user`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setApiLoading(false);
        return res.data;
      })
      .catch((err) => {
        setApiLoading(false);
        console.log(err.response.data.message);
        return false;
      });
    return data;
  };

  const verifyRolePermission = (rolesArray: string[]) => {
    const canAccess = seller?.roles
      .map((role) => role.name)
      .some((role) => rolesArray.includes(role));
    if (canAccess) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <SellerContext.Provider
      value={{
        seller,
        getRoles,
        setSeller,
        verifyUserLoggedIn,
        token,
        verifyRolePermission,
        activateUser,
        isLogged,
        Logout,
        getSeller,
        registerUser,
        resetPassword,
        Login,
        getAllSellers,
        setAllSellers,
        allSellers,
        createAddress,
        updateUser,
        apiLoading,
        roleAdmin,
        isActive,
        sendPasswordToken,
        createLegalTerms,
        roleRecruit,
        sendActivationToken,
        roleFinance,
        sales,
        pageMounting,
        setApiLoading,
        setPageMounting,
        updatePassword,
      }}
    >
      {children}
    </SellerContext.Provider>
  );
};

export const useSeller = () => useContext(SellerContext);
