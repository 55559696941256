import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { IRole } from "../../providers/roles/roles.interface";
import ModalChangePassword from "../ModalChangePasword";
import PageHeader from "../pageHeader";
import { RxAvatar } from "react-icons/rx";

const ProfileContainer = () => {
  const { seller } = useSeller();
  const { path } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalChangePassword, setModalChangePassword] = useState(false);
  return (
    <>
      <Container>
        <PageHeader
          title={t("profile")}
          description={t("profile")}
          icon={<RxAvatar />}
        />

        <div className="panel">
          <div className="panelHeader">
            <h4>{t("user")}</h4>
          </div>
          <div>
            <div className="profileAvatar">
              <img
                src={
                  seller.userProfileImage
                    ? seller.userProfileImage.url
                    : "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
                }
                alt="..."
              />
            </div>
            <div className="profileHeader">
              <h4>{`${seller.first_name} ${seller.last_name}`}</h4>
              <small>
                {" "}
                {seller.roles.map((u: IRole) => u.name).join(", ")}{" "}
              </small>
              <div className="profileButtons">
                <Button
                  variant="contained"
                  onClick={() => navigate(`/${path}/profile/update`)}
                >
                  {t("update_profile")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setModalChangePassword(true)}
                >
                  {t("update_password")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="panel">
          <div className="panelHeader">
            <h4>{t("profile")}</h4>
          </div>
          <div className="panelBody">
            <div className="bodyItem">
              <p>
                <strong>{t("email")}</strong>
              </p>
              <p className="lastItem">{seller.email}</p>
            </div>
            <div className="bodyItem">
              <p>
                <strong>{t("phone_number")}</strong>
              </p>
              <p className="lastItem">{seller.phone}</p>
            </div>
            <div className="bodyItem">
              <p>
                <strong>{t("date_birth")}</strong>
              </p>
              <p className="lastItem">
                {" "}
                {t("date_format", {
                  month: seller.date_birth.split("-")[1],
                  day: seller.date_birth.split("-")[2],
                  year: seller.date_birth.split("-")[0],
                })}
              </p>
            </div>
            <div className="bodyItem">
              <p>
                <strong>{t("gender")}</strong>
              </p>
              <p className="lastItem">{t(seller.gender)}</p>
            </div>
            <div className="bodyItem">
              <p>
                <strong>{t("language")}</strong>
              </p>
              <p className="lastItem">
                {seller.default_language === "english"
                  ? t("english")
                  : t("portuguese")}
              </p>
            </div>
          </div>
        </div>

        <div className="panel">
          <div className="panelHeader">
            <h4>{t("address")}</h4>
          </div>
          {seller.address ? (
            <div className="panelBody">
              <div className="bodyItem">
                <p>
                  <strong>{t("country")}</strong>
                </p>
                <p className="lastItem">{seller.address.country}</p>
              </div>
              <div className="bodyItem">
                <p>
                  <strong>{t("state")}</strong>
                </p>
                <p className="lastItem">{seller.address.state}</p>
              </div>
              <div className="bodyItem">
                <p>
                  <strong>{t("address")}</strong>
                </p>
                <p className="lastItem">{seller.address.address_line}</p>
              </div>
              {seller.address.address_line_2 ? (
                <div className="bodyItem">
                  <p>
                    <strong>{t("address_line_2")}</strong>
                  </p>
                  <p className="lastItem">{seller.address.address_line_2}</p>
                </div>
              ) : (
                <></>
              )}
              <div className="bodyItem">
                <p>
                  <strong>{t("city")}</strong>
                </p>
                <p className="lastItem">{seller.address.city}</p>
              </div>
              <div className="bodyItem">
                <p>
                  <strong>{t("zip_code")}</strong>
                </p>
                <p className="lastItem">{seller.address.zip_code}</p>
              </div>
              <Button
                sx={{ ml: 1, mt: 1 }}
                variant="contained"
                onClick={() => navigate(`/${path}/profile/address`)}
              >
                {t("update_address")}
              </Button>
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={() => navigate(`/${path}/profile/address`)}
            >
              {t("add")} {t("address")}
            </Button>
          )}
        </div>

        {seller.salesTeam ? (
          <div className="panel">
            <div className="panelHeader">
              <h4>{t("sales_team")}</h4>
            </div>
            <div className="panelBody">
              <div className="bodyItem">
                <p>
                  <strong>{t("name")}</strong>
                </p>
                <p className="lastItem">{seller.salesTeam.name}</p>
              </div>
              <div className="bodyItem">
                <p>
                  <strong>{t("description")}</strong>
                </p>
                <p className="lastItem">{seller.salesTeam.description}</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Container>
      {modalChangePassword && (
        <ModalChangePassword setModalChangePassword={setModalChangePassword} />
      )}
    </>
  );
};

export default ProfileContainer;
