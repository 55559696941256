import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/logo-removebg-preview.svg";
import LoginForm from "../../components/LoginForm";
import TokenVerificationForm from "../../components/tokenVerificationForm";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import { IRole } from "../../providers/roles/roles.interface";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = () => {
  const { isLogged, isActive, seller } = useSeller();
  const navigate = useNavigate();
  const { path } = useParams();
  const query = useQuery();
  const { i18n } = useTranslation();
  useEffect(() => {
    const language = query.get("language");
    if (language) {
      i18n.changeLanguage(language === "pt" ? "pt" : "en");
    }
    if (isLogged && isActive === true) {
      if (seller && seller.roles.some((e: IRole) => e.name === "Recruit")) {
        const recruitment_id =
          window.localStorage.getItem("SPP-Recruitment-ID") || "";
        if (recruitment_id) {
          return navigate(
            "/" +
              path +
              "/recruitments/" +
              window.localStorage.getItem("SPP-Recruitment-ID") +
              "/candidate"
          );
        }

        navigate("/" + path + "/candidate");
      } else {
        window.localStorage.removeItem("SPP-Recruitment-ID");
        navigate(`/${path}/dashboard`);
      }
    }
  }, [seller]);

  return (
    <Container>
      <header>
        <img src={logo} alt="SPP Logo" className="loginLogo" />
      </header>
      {isLogged === true && isActive === false ? (
        <section className="formContainer">
          <TokenVerificationForm />
        </section>
      ) : (
        <LoginForm />
      )}
    </Container>
  );
};

export default Login;
