import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  padding: 1px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  header {
    display: flex;
    justify-content: center;
  }
  .registerLogo {
    width: 220px;
    height: 120px;
  }
  @media (min-width: 1100px) {
    max-width: 80vw;
    padding: 0px 200px;
    margin: 0 auto;
    width: 100%;
  }
`;
