import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSeller } from "../../providers/users";
import { Container } from "./styles";
import logo from "../../assets/logo-removebg-preview.svg";
import RegisterForm from "../../components/RegisterForm";

const RegisterPage = () => {
  const { isLogged, isActive, seller } = useSeller();
  const navigate = useNavigate();
  const { path } = useParams();

  useEffect(() => {
    if (isLogged && isActive === true) {
      navigate(`/${path}/dashboard`);
    }
  }, [seller]);

  return (
    <Container>
      <header>
        <img src={logo} alt="SPP Logo" className="registerLogo" />
      </header>
      <RegisterForm />
    </Container>
  );
};

export default RegisterPage;
